import i18n from '@/i18n/i18n'

export const mainSteps: any = [
  {
    target: '#btnVNavMenuMinToggler',
    content: i18n.t('selecione-aqui-para-recolher-o-menu-lateral'),
    breakpoint: 'xl'
  },
  {
    target: '.vx-navbar__starred-pages',
    content:
        i18n.t('crie-seus-proprios-favoritos-voce-tambem-pode-reorganiza-los-usando-arrastar-e-soltar'),
    breakpoint: 'xl'
  },
  {
    target: '.navbar-fuzzy-search',
    content: i18n.t('pesquise-aqui-as-funcionalidades-do-sistema')
  }
]

export const questionnaireIntroSteps: any = [
  {
    target: '#model_name',
    content: i18n.t('nome-do-questionario-sera-utilizado-internamente-para-facilitar-a-busca')
  },
  {
    target: '#description',
    content: i18n.t('esta-informacao-sera-utilizada-para-as-instrucoes-iniciais-para-o-participante-antes-do-inicio-do-questionario-portanto-preencha-com-todos-os-detalhes-que-deseja-informar-ao-participante')
  },
  {
    target: '.config',
    content: i18n.t('configuracoes-avancadas-veja-com-mais-detalhes-todas-as-opcoes-que-voce-pode-configurar-para-obter-todo-o-poder-do-questionario-educatena')
  },
  {
    target: '.tour-config-1',
    content: i18n.t('nota-minima-para-considerar-o-participante-como-aprovado')
  },
  {
    target: '.tour-config-2',
    content: i18n.t('o-participante-deve-responder-o-questionario-dentro-do-tempo-limite')
  },
  {
    target: '.tour-config-3',
    content: i18n.t('defina-uma-senha-segura-para-limitar-o-acesso-ao-questionario')
  },
  {
    target: '.tour-config-4',
    content: i18n.t('o-questionario-so-podera-ser-respondido-dentro-do-periodo-definido')
  },
  {
    target: '.tour-config-5',
    content: i18n.t('para-saber-mais-sobre-as-outras-opcoes-basta-segurar-o-dedo-ou-o-cursor-do-mouse-na-opcao')
  },
]

export const questionnaireEditorIntroSteps: any = [
  {
    target: '#editor-panel',
    content: i18n.t('use-estas-questoes-para-arrastar-e-soltar-e-criar-o-seu-questionario')
  },
  {
    target: '#tour-editor-drop',
    content: i18n.t('arraste-as-questoes-para-esta-area-e-solte-para-adicionar-novas-questoes')
  }
]

export const questionnairePublicationIntroSteps: any = [
  //{
  //    target: '.tour-questionnaire-publication',
  //    content: 'Link do questionário.'
  //},
  {
    target: '.tour-publication-1',
    content: i18n.t('quando-terminar-de-confeccionar-as-questoes-e-definir-as-configuracoes-esta-na-hora-de-publicar-seu-questionario-para-os-alunos')
  },
  {
    target: '.tour-publication-2',
    content: i18n.t('ha-duas-formas-para-os-participantes-acessarem-o-questionario')
  },
  {
    target: '.tour-publication-3',
    content: i18n.t('1-compartilhando-o-link-do-questionario-com-eles')
  },
  {
    target: '.tour-publication-4',
    content: i18n.t('2-utilizar-o-sistema-para-enviar-email-com-o-link-para-usuarios-cadastrados-ou-emails-avulsos')
  },
  {
    target: '.tour-publication-5',
    content: i18n.t('voce-pode-marcar-todos-os-alunos-de-turmas-escolhidas-por-voce-neste-campo')
  },
  {
    target: '.tour-publication-6',
    content: i18n.t('ou-digitar-manualmente-uma-lista-de-emails-para-serem-selecionados-dica-de-ouro-e-possivel-copiar-e-colar-uma-lista-de-emails-separados-por-virgula-e-o-sistema-reconhecera')
  },
  {
    target: '.tour-publication-7',
    content: i18n.t('apos-selecionados-todos-os-emails-sejam-de-alunos-de-uma-turma-ou-emails-avulsos-quando-clicar-neste-botao-pela-primeira-vez-o-questionario-sera-liberado-para-que-os-participantes-possam-responde-lo')
  }
]

export const questionnaireAnswersIntroSteps: any = [
  {
    target: '.tour-answers-refresh',
    content: i18n.t('carregar-respostas-do-questionario')
  }
]

export const questionnaireAnswerHasAnswerSteps: any = [
  {
    target: '.tour-answers-1',
    content: i18n.t('voce-pode-clicar-aqui-para-mostrar-somente-as-questoes-sem-correcao')
  },
  {
    target: '.tour-answers-2',
    content: i18n.t('e-possivel-alterar-como-visualizara-as-respostas-agrupando-as-por-usuario-ou-questao')
  },
  {
    target: '.tour-answers-3 .vs-pagination--ul',
    content: i18n.t('aqui-voce-pode-mudar-qual-usuario-ou-questao-esta-selecionada')
  },
  {
    target: '.tour-answers-9',
    content: i18n.t('quando-terminar-de-corrigir-todas-as-respostas-clique-em-liberar-notas')
  }
]

export const questionnaireResumeIntroSteps: any = [
  {
    target: '.tour-resume-refresh',
    content: i18n.t('carregar-resumo-do-questionario')
  }
]

export const questionnaireResumeExplanationSteps: any = [
  {
    target: '.tour-group',
    content: i18n.t('escolha-como-gostaria-de-visualizar-as-notas-em-forma-de-lista-com-a-nota-de-cada-participante-ou-atraves-de-graficos-de-desempenho-por-questao')
  }
]
